var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-row',{staticClass:"mb-6",attrs:{"gutter":10,"type":"flex","justify":"center"}},[_c('el-col',{attrs:{"span":16}},[_vm._t("campaignName")],2),_c('el-col',{attrs:{"span":8}},[_c('btn-icons',{attrs:{"selectList":_vm.selectList},on:{"editName":_vm.editName,"editDate":_vm.editDateShowClick,"editStatus":_vm.editStatus}})],1)],1),_c('el-table',{attrs:{"data":_vm.tableData,"border":"","tree-props":{ children: 'ads' },"show-summary":true,"sum-text":" ","summary-method":_vm.getSummaries,"row-key":"index"},on:{"selection-change":_vm.selectAds}},[_c('el-table-column',{attrs:{"type":"selection","width":"55"}}),_c('el-table-column',{attrs:{"prop":"name","label":"广告组名称","min-width":"220px"},scopedSlots:_vm._u([{key:"default",fn:function({ row, $index }){return [_c('el-form',{key:row.index,ref:'formName' + $index,attrs:{"model":row,"label-width":"0"}},[_c('el-form-item',{attrs:{"label":"","prop":"name","rules":[
              {
                validator: (rule, value, callback) => _vm.validatorName(rule, value, callback, row),
                trigger: ['blur', 'change'],
              },
            ]}},[_c('row-name',{on:{"input":_vm.emitInput},model:{value:(row),callback:function ($$v) {row=$$v},expression:"row"}})],1)],1)]}}])}),_c('el-table-column',{attrs:{"prop":"status","label":"广告组状态","width":"240"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(row.level == 'adset')?_c('el-radio-group',{on:{"input":_vm.emitInput},model:{value:(row.status),callback:function ($$v) {_vm.$set(row, "status", $$v)},expression:"row.status"}},[_c('el-radio',{attrs:{"label":""}},[_vm._v("默认")]),_vm._l((_vm.enumConstants.statuses),function(item){return _c('el-radio',{attrs:{"label":item.key}},[_vm._v(_vm._s(item.value))])})],2):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"广告组排期","width":"320"},scopedSlots:_vm._u([{key:"default",fn:function({ row, $index }){return [(row.level == 'adset')?_c('div',[_c('date',{ref:"formDate",on:{"input":_vm.emitInput},model:{value:(row),callback:function ($$v) {row=$$v},expression:"row"}})],1):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"受众人群(万)","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.targeting)?_c('div',[_c('span',[_c('i',{staticClass:"el-icon-s-custom"}),_vm._v(_vm._s(scope.row.targeting.audienceSize.minimum)+" - "+_vm._s(scope.row.targeting.audienceSize.maximum)+" ")])]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"广告组预算","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function({ row, $index }){return [_c('el-form',{ref:'formBudget' + $index,attrs:{"model":row,"label-width":"0"}},[_c('el-form-item',{attrs:{"label":"","prop":"budgetAmount","rules":[
              {
                validator: (rule, value, callback) => _vm.validatorBudget(rule, value, callback, row),
                trigger: ['blur', 'change'],
              },
            ]}},[(row.level == 'adset')?_c('row-budget',{attrs:{"budgetTypes":_vm.enumConstants.budgetTypes},on:{"input":_vm.emitInput},model:{value:(row),callback:function ($$v) {row=$$v},expression:"row"}}):_vm._e()],1)],1)]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"竞价策略","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function({ row, $index }){return [_c('el-form',{ref:'formBid' + $index,attrs:{"model":row,"label-width":"0"}},[_c('el-form-item',{attrs:{"label":"","prop":"bidAmount","rules":[
              {
                validator: (rule, value, callback) => _vm.validatorBid(rule, value, callback, row),
                trigger: ['blur', 'change'],
              },
            ]}},[(row.level == 'adset')?_c('row-bid',{attrs:{"bidStrategys":_vm.enumConstants.bidStrategies},on:{"input":_vm.emitInput},model:{value:(row),callback:function ($$v) {row=$$v},expression:"row"}}):_vm._e()],1)],1)]}}])})],1),_c('edit-name',{attrs:{"level":_vm.editNameLevel,"editNameShow":_vm.editNameShow},on:{"update:editNameShow":function($event){_vm.editNameShow=$event},"update:edit-name-show":function($event){_vm.editNameShow=$event},"setName":_vm.setName}}),_c('edit-date',{attrs:{"editDateShow":_vm.editDateShow},on:{"update:editDateShow":function($event){_vm.editDateShow=$event},"update:edit-date-show":function($event){_vm.editDateShow=$event},"setDate":_vm.setDate}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }