export const budgetTypes = [
  { label: '日预算', value: 'DAILY_BUDGET' },
  { label: '总预算', value: 'lIFTTIME_BUDGET' },
];
export const conversionWindows = [
  { label: '28+1（默认）', value: 'SWIPE_28DAY_VIEW_1DAY' },
  { label: '7+0', value: 'SWIPE_7DAY' },
];
export const optimizationGoals = [
  { label: '像素购买', value: 'PIXEL_PURCHASE' },
  { label: '像素注册', value: 'PIXEL_SIGNUP' },
  { label: '像素加入购物车', value: 'APP_ADD_TO_CART' },
];
export const bidStrategys = [
  { label: '自动竞价', value: 'AUTO_BID' },
  { label: '最高出价', value: 'LOWEST_COST_WITH_MAX_BID' },
];
export const rules = {
  budgetAmount: [{ required: true, message: '请填写出价金额', trigger: ['change', 'blur'] }],
  startTime: [{ required: true, message: '请选择开始时间', trigger: ['change', 'blur'] }],
  endTime: [{ required: true, message: '请选择结束时间', trigger: ['change', 'blur'] }],
  bidAmount: [{ required: true, message: '请填写出价金额', trigger: ['change', 'blur'] }],
  bidStrategy: [{ required: true, message: '请选择竞价类型', trigger: ['change', 'blur'] }],
  optimizationGoal: [{ required: true, message: '请选择优化目标', trigger: ['change', 'blur'] }],
  conversionWindow: [{ required: true, message: '请选择转化窗口', trigger: ['change', 'blur'] }],
};
export const splitTableView = [
  {
    bidAmount: 30,
    bidStrategy: 'LOWEST_COST_WITH_MAX_BID',
    budgetAmount: '10',
    budgetType: 'lIFTTIME_BUDGET',
    conversionWindow: 'Lorem',
    name: 'reprehenderit esse nostrud cupidatat Ut',
    optimizationGoal: 'nisi quis ad laboris culpa',
    startTime: '2024-06-08 12:24:324',
    endTime: '2024-09-08 12:24:324',
    status: '',
    targeting: {
      autoExpansionOptions: true,
      gender: 'ut dolor',
      geos: [
        {
          countryCode: 'dolor in elit laborum',
          postalCode: ['dolore pariatur', 'tempor occaecat do ex cupidatat', 'labore dolore est'],
          metroId: ['amet ipsum veniam'],
          regionId: ['aute nisi enim'],
          operation: 'tempor aute exercitation qui voluptate',
        },
        {
          countryCode: 'do labore sit occaecat eiusmod',
          postalCode: ['irure velit exercitation ad in', 'ut aliquip qui', 'veniam in'],
          metroId: ['minim'],
          regionId: ['laborum adipisicing deserunt culpa'],
          operation: 'deserunt',
        },
      ],
      interests: [
        {
          categoryId: ['Excepteur exercitation cupidatat adipisicing', 'Excepteur id pariatur'],
          operation: 'proident',
        },
        {
          categoryId: ['cillum sunt'],
          operation: 'adipisicing mollit Lorem aliqua ut',
        },
      ],
      languages: ['sed cupidatat'],
      maxAge: 'consequat est sed',
      minAge: 'consequat eiusmod deserunt',
      segments: [
        {
          segmentId: ['reprehenderit voluptate', 'irure adipisicing', 'aute proident eiusmod nisi'],
          operation: 'commodo do adipisicing sed ex',
        },
        {
          segmentId: ['sed id Excepteur aliqua veniam', 'exercitation dolore officia Excepteur'],
          operation: 'sit dolor tempor',
        },
        {
          segmentId: ['minim Duis', 'et Excepteur'],
          operation: 'ut',
        },
      ],
      audienceSize: {
        minimum: 84865599,
        maximum: -13281095,
      },
    },
    ads: [
      {
        name: 'minim pariatur voluptate Ut nisi',
        status: 'nisi',
        creative: {
          brandName: 'Duis',
          callToAction: 'aliquip',
          headline: 'non',
          mediaUrl: 'nostrud occaecat nisi fugiat',
          webViewUrl: 'culpa dolore in',
        },
      },
      {
        name: 'sint consequat in incididunt elit',
        status: 'nulla enim Duis velit in',
        creative: {
          brandName: 'esse commodo',
          callToAction: 'et ad pariatur voluptate',
          headline: 'Excepteur magna commodo ipsum amet',
          mediaUrl: 'in ut',
          webViewUrl: 'aute consectetur eu',
        },
      },
      {
        name: 'Excepteur Duis in nisi irure',
        status: 'pariatur in ipsum occaecat mollit',
        creative: {
          brandName: 'do',
          callToAction: 'anim Lorem non fugiat officia',
          headline: 'consectetur',
          mediaUrl: 'sint pariatur commodo sit',
          webViewUrl: 'eiusmod aute Ut tempor labore',
        },
      },
    ],
  },
];
export const params = {
  promotionObjectType: 'LANDING_PAGE',
  objective: 'WEB_CONVERSION',
  adAccountId: 'fda228a1-3938-44d7-ac65-caa6497e6348',
  promotionInfo: {
    promotionUrl: 'https://iebonshop.com/index/detail.html?sno=MTAwNzAyOTUwMg==&coll_id=1001960881&cstpzptiirbjfc=',
    sale: {
      id: 1007029502,
      saleName: 'صندل جيد التهوية مقاوم للانزلاق ذو نعل سميك 5 سم ',
      price: '663.0',
      specialPrice: '199',
      currencyId: 14,
      productId: 559267,
      productName: 'JS-大码防滑透气女拖鞋',
      domain: '',
      imageUrl: 'https://oss.giikin.cn/uploads/4969ca75143a381a6bc1e4b99c896192.jpg',
      seoUrl: '',
      areaId: 117,
      orgCode: '101402',
      uploader: 10037,
      currency: 'SAR',
    },
    site: {
      id: 1001960881,
      name: '【已开启多币种】沙特阿语-iebonshop.com',
      recommener: 0,
      orgCode: '101402',
      domain: 'iebonshop.com',
      areaId: 117,
      countryCode: { id: 11, code: 'SA', name: '沙特阿拉伯', currencyId: 14 },
    },
  },
  pixelId: '4d2f9982-dc54-4d70-a62c-d31cbbe131a6',
  adGroupTargeting: [
    {
      geos: ['KW'],
      min_age: '13',
      max_age: '50+',
      gender: 'OTHER',
      languages: ['da'],
      interests: [],
      segments: [
        { segmentId: ['5046865343920566'], operation: 'INCLUDE' },
        { segmentId: [], operation: 'EXCLUDE' },
      ],
      autoExpansionOptions: true,
    },
  ],
  creatives: [
    {
      type: 'WEB_VIEW',
      infos: [
        {
          name: 'WEB_VIEW',
          mediaUrl: 'https://gamsad.giikin.cn/ads-media/20240621/667526dae4b04b10ed5e816f.jpg',
          headline: '454512165354',
          callToAction: 'SHOP_NOW',
          brandName: '3232434',
          mediaType: 'IMAGE',
          landingPageUrl:
            'https://iebonshop.com/index/detail.html?sno=MTAwNzAyOTUwMg==&coll_id=1001960881&cstpzptiirbjfc=',
        },
      ],
    },
  ],
};
